.modal-change-pwd {
  h5 {
    font: normal normal 600 20px/24px Usual;
    color: #3a2d83;
    margin-top: 70px;
  }
  .button {
    padding: 8px 16px;
  }
  .modal-body {
    padding-right: 26px;
    font: normal normal 400 14px/24px Usual;
    color: black;
  }
}
@media only screen and (max-width: 767px) {
  .modal-change-pwd {
    .modal-body {
      padding-right: 26px;
    }
  }
}
.close:hover {
  background: #f9f9fa;
  border-radius: 4px;
}
