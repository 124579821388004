/*
 * The Typekit service used to deliver this font or fonts for use on websites
 * is provided by Adobe and is subject to these Terms of Use
 * http://www.adobe.com/products/eulas/tou_typekit. For font license
 * information, see the list below.
 *
 * usual:
 *   - http://typekit.com/eulas/00000000000000000001771b
 *   - http://typekit.com/eulas/00000000000000000001771c
 *   - http://typekit.com/eulas/00000000000000000001771d
 *   - http://typekit.com/eulas/00000000000000000001771e
 *   - http://typekit.com/eulas/00000000000000000001771f
 *   - http://typekit.com/eulas/000000000000000000017720
 *   - http://typekit.com/eulas/000000000000000000017721
 *   - http://typekit.com/eulas/000000000000000000017722
 *   - http://typekit.com/eulas/000000000000000000017723
 *   - http://typekit.com/eulas/000000000000000000017724
 *
 * © 2009-2020 Adobe Systems Incorporated. All Rights Reserved.
 */
/*{"last_published":"2020-10-12 12:11:55 UTC"}*/

@import url('https://p.typekit.net/p.css?s=1&k=rhk1bhe&ht=tk&f=25629.25630.25631.25632.25633.25634.25635.25636.25637.25638&a=35396887&app=typekit&e=css');

@font-face {
  font-family: 'usual';
  src: url('https://use.typekit.net/af/4e8f1c/00000000000000000001771b/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/4e8f1c/00000000000000000001771b/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3')
      format('woff'),
    url('https://use.typekit.net/af/4e8f1c/00000000000000000001771b/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3')
      format('opentype');
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: 'usual';
  src: url('https://use.typekit.net/af/9f8f7d/00000000000000000001771c/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i6&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/9f8f7d/00000000000000000001771c/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i6&v=3')
      format('woff'),
    url('https://use.typekit.net/af/9f8f7d/00000000000000000001771c/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i6&v=3')
      format('opentype');
  font-display: swap;
  font-style: italic;
  font-weight: 600;
}

@font-face {
  font-family: 'usual';
  src: url('https://use.typekit.net/af/393a58/00000000000000000001771d/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/393a58/00000000000000000001771d/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3')
      format('woff'),
    url('https://use.typekit.net/af/393a58/00000000000000000001771d/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3')
      format('opentype');
  font-display: swap;
  font-style: normal;
  font-weight: 600;
}

@font-face {
  font-family: 'usual';
  src: url('https://use.typekit.net/af/b5c416/00000000000000000001771e/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i3&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/b5c416/00000000000000000001771e/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i3&v=3')
      format('woff'),
    url('https://use.typekit.net/af/b5c416/00000000000000000001771e/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i3&v=3')
      format('opentype');
  font-display: swap;
  font-style: italic;
  font-weight: 300;
}

@font-face {
  font-family: 'usual';
  src: url('https://use.typekit.net/af/8d84f0/00000000000000000001771f/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/8d84f0/00000000000000000001771f/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3')
      format('woff'),
    url('https://use.typekit.net/af/8d84f0/00000000000000000001771f/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3')
      format('opentype');
  font-display: swap;
  font-style: normal;
  font-weight: 300;
}

@font-face {
  font-family: 'usual';
  src: url('https://use.typekit.net/af/a20d40/000000000000000000017720/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/a20d40/000000000000000000017720/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3')
      format('woff'),
    url('https://use.typekit.net/af/a20d40/000000000000000000017720/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3')
      format('opentype');
  font-display: swap;
  font-style: italic;
  font-weight: 400;
}

@font-face {
  font-family: 'usual';
  src: url('https://use.typekit.net/af/70d624/000000000000000000017721/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i8&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/70d624/000000000000000000017721/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i8&v=3')
      format('woff'),
    url('https://use.typekit.net/af/70d624/000000000000000000017721/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i8&v=3')
      format('opentype');
  font-display: swap;
  font-style: italic;
  font-weight: 800;
}

@font-face {
  font-family: 'usual';
  src: url('https://use.typekit.net/af/8efdc5/000000000000000000017722/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n8&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/8efdc5/000000000000000000017722/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n8&v=3')
      format('woff'),
    url('https://use.typekit.net/af/8efdc5/000000000000000000017722/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n8&v=3')
      format('opentype');
  font-display: swap;
  font-style: normal;
  font-weight: 800;
}

@font-face {
  font-family: 'usual';
  src: url('https://use.typekit.net/af/84cfc3/000000000000000000017723/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/84cfc3/000000000000000000017723/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3')
      format('woff'),
    url('https://use.typekit.net/af/84cfc3/000000000000000000017723/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3')
      format('opentype');
  font-display: swap;
  font-style: italic;
  font-weight: 700;
}

@font-face {
  font-family: 'usual';
  src: url('https://use.typekit.net/af/625a3c/000000000000000000017724/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/625a3c/000000000000000000017724/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3')
      format('woff'),
    url('https://use.typekit.net/af/625a3c/000000000000000000017724/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3')
      format('opentype');
  font-display: swap;
  font-style: normal;
  font-weight: 700;
}

.tk-usual {
  font-family: 'usual', sans-serif;
}
