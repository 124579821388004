.sidebar-container {
  height: 100vh;
  width: 19rem;
  background: #f9f9fa;
  box-shadow: 4px 4px 8px rgba(141, 138, 160, 0.05), -4px -4px 8px rgba(141, 138, 160, 0.05);
  .sidebar-row {
    padding-top: 2rem;
    max-width: 15rem;
  }
  .username {
    font: normal normal 600 16px/24px Usual;
    position: relative;
    color: #212027;
  }
  .email {
    font: normal normal normal 14px/16px Usual;
    color: #adabbe;
  }
  hr {
    background: #eeeef2;
    opacity: 1;
  }
  .menu {
    > h3 {
      color: #454351;
      font: normal normal 600 14px/24px Usual;
      margin-bottom: 10px;
    }
  }
  .back-to-app {
    cursor: pointer;
    a {
      font: normal normal 400 14px/24px Usual;
      color: #454351;
    }
  }
  .menu-item {
    color: #454351;
    font: normal normal 400 14px/24px Usual;
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
    img:nth-of-type(1) {
      display: block;
    }
    img:nth-of-type(2) {
      display: none;
    }
    &.active,
    &:hover {
      background-color: #efefff;
      color: #635cff;
      font-weight: 600;
      img:nth-of-type(1) {
        display: none;
      }
      img:nth-of-type(2) {
        display: block;
      }
    }
  }
  .sign-out {
    color: #454351;
    font: normal normal normal 14px/24px Usual;
    cursor: pointer;
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
    &:hover {
      background-color: #efefff;
    }
  }
  @media (max-width: 767px) {
    position: fixed;
    z-index: 1;
    width: 270px;
  }
}
