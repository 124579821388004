/* You can add global styles to this file, and also import other style files */
@import './assets/fonts/font.scss';
@import './Colors.scss';
html,
body {
  height: 100%;
  position: relative;
  margin: 0;
  box-sizing: border-box;
  font-family: Usual, Helvetica, Arial, sans-serif;
}

.full-page-container {
  height: calc(100vh - 114px);
}
body.bg-blue {
  background: #f4f8fe;
}
body.bg-home {
  background-image: url('./assets/img/picto/illu-waves-bottom.svg');
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: 100% auto;
}
app-root {
  min-height: 100%;
  // overflow: hidden;
  display: block;
  position: relative;
}

.section-header {
  padding-top: 70px;
  padding-bottom: 40px;
}

header {
  position: sticky;
  top: 0;
  z-index: 99;
}

.footer-absolute {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.ico {
  display: block;
  color: white;
  text-decoration: none;
  width: 18px;
  height: 18px;
}
.ico:before {
  content: '';
  background-size: cover;
  position: absolute;
  width: 18px;
  height: 18px;
  background-repeat: no-repeat !important;
}
.alert {
  font-size: 13px;
  padding: 5px;
  margin-top: 5px;
  font-family: Usual, Helvetica, Arial, sans-serif;
}

.button:disabled,
.button[disabled] {
  opacity: 0.7;
}
.button {
  padding: 13px 20px;
  margin: 0 10px;
  font: normal normal 600 14px/18px 'usual';
  text-decoration: none;
  color: $color-primary !important;
  display: inline-block;
  cursor: pointer;
  &:after {
    content: '';
    width: 12px;
    height: 11px;
    top: 1px;
    right: -7px;
    position: relative;
    display: inline-block;
  }
}
.button.primary:after,
.button.secondary:after,
.button.mb:after {
  background: transparent url('./assets/img/picto/svg_icon_arrow_button.svg') no-repeat;
}
.button:hover {
  &:after {
    width: 12px;
    background: transparent url('./assets/img/picto/svg_button_arrow_actif.svg') no-repeat;
    content: '';
    height: 11px;
    top: 1px;
    right: -7px;
    position: relative;
    display: inline-block;
  }
}
.button.simple {
  &:hover {
    &:after {
      background: none;
      width: 0px;
    }
  }
  &:after {
    background: none;
    width: 0px;
  }
}
.button.primary:hover {
  background-color: #01d5b3 !important;
  border: 1px solid #01d5b3;
}
.button.secondary:hover {
  background-color: #00e1bdb3 !important;
}
.button.primary {
  border-radius: 5px;
  background-color: $color-secondary;
  border: 1px solid $color-secondary;
}
.button.secondary {
  border-radius: 5px;
  border: 1px solid #00e1bd;
}
button:focus {
  outline: 0 !important;
}
.row > * {
  width: auto;
}
a {
  text-decoration: unset;
}
.cta-blue {
  font: normal normal 600 14px/24px Usual;
  color: #635cff;
  cursor: pointer;
}
.error-container {
  img {
    margin-right: 12px;
  }
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px 24px;
  background: #ffe7f0;
  border-radius: 8px;
  color: #ff126d;
  font: normal normal 600 14px/24px Usual;
}
.toast-success-container {
  &.error {
    color: #ff126d;
  }
  img {
    margin-right: 12px;
  }
  > div {
    padding-top: 4px;
  }
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  border-radius: 8px;
  color: #01d5b3;
  font: normal normal 600 14px/22px Usual !important;
  .toast-desc {
    color: #454351;
    font: normal normal 500 14px/24px Usual !important;
  }
}

.main-card {
  display: flex;
  flex-direction: column;
  width: 410px;
  margin-top: 72px;
  max-width: 100%;
  h5 {
    font: normal normal 700 22px/32px Usual;
    color: #3a2d83;
    margin-top: 70px;
  }
  .desc {
    font: normal normal 400 14px/24px Usual;
    color: #454351;
    margin-top: 45px;
    margin-bottom: 3px;
    max-width: 385px;
  }
  .button.primary {
    width: 100%;
  }
}
.container-fluid {
  @media (min-width: 1200px) {
    .background {
      width: 480px !important;
    }
  }
  @media (min-width: 900px) {
    .row {
      justify-content: space-between !important;
      margin-left: 80px;
    }
    .background {
      width: 240px;
      background-color: #3a2d83;
      height: 100vh;
      float: right;
      background-image: url('assets/img/picto/illu-background-auth.svg');
      background-repeat: no-repeat;
      background-size: cover;
    }
  }
}
@media only screen and (max-width: 653px) {
  .main-card {
    margin-top: 48px;
    h5 {
      margin-top: 40px;
    }
    .alert-message {
      max-width: 410px;
    }
  }
}
@media only screen and (max-width: 500px) {
  .main-card {
    .alert-message {
      max-width: 100%;
      word-break: break-word;
    }
  }
}
