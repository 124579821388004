.top-sidebar-container {
  position: sticky;
  top: 0;
  background: white;
  box-shadow: 4px 4px 16px rgba(141, 138, 160, 0.1), -4px -4px 16px rgba(141, 138, 160, 0.1);
  .logo {
    img {
      margin-left: -30px;
    }
  }
}
