.main-card {
  p.bottom-link {
    font: normal normal normal 14px/24px Usual;
    color: #3a2d83;
    a {
      font: normal normal 600 14px/24px Usual;
    }
  }
}
.bai-modal {
  width: 100% !important;
  max-width: 50.5rem !important;
  position: relative;
  margin: 10% auto;
  background: rgb(255, 255, 255);
  box-shadow: 8px 8px 24px rgba(141, 138, 160, 0.15), -8px -8px 24px rgba(141, 138, 160, 0.15) !important;
  border-radius: 8px !important;
  padding: 0px !important;
  margin: 10% auto !important;
  &.update-name, &.change-password {
    max-width: 29.06rem !important;
  }
  .modal-dialog {
    max-width: 100% !important;
  }
}
.break {
  flex-basis: 100%;
  height: 0;
}
.Toastify__toast-container {
  z-index: 99999 !important;
  width: 430px !important;
  max-width: 90% !important;
  margin: 10px auto !important;
}
.Toastify__toast {
  border: 1px solid #eeeef2 !important;
  box-sizing: border-box;
  box-shadow: 4px 4px 16px rgba(141, 138, 160, 0.1), -4px -4px 16px rgba(141, 138, 160, 0.1) !important;
  border-radius: 8px !important;

  .close {
    height: fit-content;
  }
}
.Toastify__progress-bar {
  background: #01d5b3 !important;
}
.Toastify__toast--error {
  .Toastify__toast-icon {
    display: none;
  }
  .Toastify__progress-bar {
    background: #ff126d !important;
  }
}

.Toastify__close-button.Toastify__close-button--light {
  padding: 8px !important;
}

.account-container {
  color: #3a2d83;
  font: normal normal 600 20px/24px Usual;
  .form-container {
    max-width: 45rem;
    .input-container {
      label {
        color: #212027;
        font: normal normal 600 16px/24px Usual;
      }
      input {
        color: #6a677b;
        font: normal normal 400 14px/24px Usual;
        padding: 8px 16px;
        background: #f9f9fa;
        border: 1px solid #adabbe;
        box-sizing: border-box;
        border-radius: 4px;
        width: 100%;
        max-width: 20rem;
        display: block;
      }
      .desc {
        color: #454351;
        font: normal normal 400 14px/24px Usual;
      }
      .no_access {
        font: normal normal 400 12px/16px Usual;
        color: #8d8aa0;
        max-width: 30.75rem;
      }
    }
    button.btn-action {
      color: #3a2d83;
      font: normal normal 600 14px/24px Usual;
      padding: 8px 16px;
      background: white;
      border-radius: 5px;
      border: 1px solid #d8d5e6;
      &:disabled {
        opacity: 0.5;
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .account-container {
    .form-item {
      margin-top: 2.25rem !important;
    }
    .input-container {
      width: 100%;
    }
  }
}
@media only screen and (max-width: 480px) {
  .account-container {
    margin: 24px 16px !important;
  }
}

@media only screen and (max-width: 767px) {
  .main-card {
    margin-top: 48px;
    h5 {
      margin-top: 40px;
    }
  }
  .modal-content {
    margin: 0px !important;
  }
  .Toastify__toast-container--top-right {
    top: 3em !important;
    right: 0;
    left: 0;
  }
  .dark-container {
    position: fixed;
    background: rgba(141, 138, 160, 0.44);
    width: 100%;
    height: 100%;
    z-index: 1;
  }
}
