* {
  box-sizing: border-box;
}
.form-input {
  margin: 24px auto;
  position: relative;
  label[for] {
    color: #6a677b;
    font: normal normal 600 14px/24px Usual;
    margin-bottom: 8px;
  }
  input {
    border: 1px solid #adabbe;
    &:hover {
      border: 1px solid #6a677b;
    }
    &:focus {
      border: 1px solid #635cff;
      box-shadow: 0 0 0 3px #0351f020;
    }
  }
  input.error {
    border-color: #ff126d;
  }
}
.message {
  display: block;
  position: relative;
  font: normal normal normal 12px/24px Usual;
  color: #ff126d !important;
  opacity: 1;
  margin: 8px 0px;
}
.error {
  input[type='text'],
  input[type='password'],
  textarea {
    color: #ff126d;
    border: 1px solid #ff126d;
  }
  .message {
    color: #ff126d !important;
  }
  .checkmark {
    border: 1px solid #ff126d;
  }
  .checkbox {
    color: #ff126d;
  }
}
.error-m {
  color: #ff126d;
  font: normal normal 400 14px/24px Usual;
}
input[type='text'],
input[type='password'],
textarea {
  width: 100%;
  /* White */
  font: normal normal 600 14px/24px Usual;
  color: #454351;
  background: #ffffff;
  /* Gray / 5 */
  border: 1px solid #adabbe;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 8px 16px;
  &:focus {
    outline: none !important;
  }
  &:-webkit-autofill {
    color: #3a2d83 !important;
  }
  &.has-error {
    border-color: #ff126d;
  }
}
::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #adabbe;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #adabbe;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #adabbe;
}
#username {
  padding-left: 44px;
  background: url('./assets/img/picto/icon-email--gray-3.svg') no-repeat scroll
    14px 13px;
  &:placeholder-shown {
    background: url('./assets/img/picto/icon-email--gray-5.svg') no-repeat
      scroll 14px 13px;
  }
}
#password,
.password {
  background: url('./assets/img/picto/icon-password--gray-3.svg') no-repeat
    scroll 14px 11px !important;
  padding-left: 44px !important;
  &:placeholder-shown {
    background: url('./assets/img/picto/icon-password--gray-5.svg') no-repeat
      scroll 14px 11px !important;
  }
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    background: url('./assets/img/picto/icon-password--gray-3.svg') no-repeat
      scroll 12px 12px !important;
  }
}
.checkbox input {
  visibility: hidden; /* <-- Hide the default checkbox. The rest is to hide and allow tabbing, which display:none prevents */
  display: block;
  height: 0;
  width: 0;
  position: absolute;
  overflow: hidden;
}
.checkbox div {
  /* <-- Style the artificial checkbox */
  position: relative;
  border-radius: 2px;
  height: 16px;
  width: 16px;
  border: 1px solid #a9a3c9;
  top: 2px;
}
/* Show the checkmark when checked */
[type='checkbox']:checked + div:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkbox div:after {
  left: -2px;
  top: -3px;
  width: 18px;
  position: absolute;
  content: url('./assets/img/picto/icon-tick.svg');
  display: none;
}
[type='checkbox']:checked + div {
  /* <-- Style its checked state */
  background-color: #635cff;
  border: 1px solid #635cff;
}
[type='checkbox']:checked + div:after {
  /* <-- Style its checked state */
  display: block;
}
