.main-card {
  p.bottom-link {
    font: normal normal normal 14px/24px Usual;
    color: #3a2d83;
    a {
      font: normal normal 600 14px/24px Usual;
    }
  }
  &.updateEmailContext {
    h5 {
      margin-top: 2rem !important;
    }
  }
  .remember-container {
    display: flex;
    justify-content: space-between;
    margin-top: 18px;
    margin-bottom: 16px;
    .form-input {
      margin: 0;
      label {
        color: rgba(69, 67, 81, 1);
      }
    }
  }
}
