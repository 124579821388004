.account-container.preference {
  .form-container {
    select.form-select {
      color: #212027;
      font: normal normal 400 12px/17px Usual;
      padding: 11px 12px;
      width: 210px;
    }
  }
}
option {
  font: normal normal 400 14px/24px Usual;
  background-color: #ffffff;
}

