.account-container.profile {
  .form-container {
    label {
      color: #212027;
      font: normal normal 600 16px/24px Usual;
    }
    .input-container {
      label {
        color: #454351;
        font: normal normal 400 14px/24px Usual;
      }
    }
  }
}
