.error-token-expired {
  .title {
    font: normal normal 600 20px/32px Usual;
    color: #3a2d83;
  }
  .desc {
    font: normal normal normal 14px/24px Usual;
    a {
      color: #00e1bd;
    }
  }
}
