.alert-message {
  padding: 12px 16px;
  border-radius: 8px;
  &.info {
    background-color: #efefff;
    .alert-header {
      color: #635cff;
    }
    .alert-action {
      color: #ef0f65;
    }
  }
  &.error {
    background-color: #ffe7f0;
    .alert-header {
      color: #ef0f65;
    }
  }
  .alert-header {
    font: normal normal 600 14px/24px Usual;
    margin-bottom: 0px;
  }
  .alert-desc {
    font: normal normal 500 14px/24px Usual;
    margin-bottom: 8px;
    color: #454351;
  }
  .alert-action {
    font: normal normal 600 14px/24px Usual;
    background: none;
    border: none;
  }
}
