.main-card {
  .form-input span {
    font: normal normal 400 12px/16px Usual;
    color: #6a677b;
    margin: 8px 0px;
    display: block;
  }
  p.bottom-link {
    font: normal normal normal 14px/24px Usual;
    color: #3a2d83;
    a {
      font: normal normal 600 14px/24px Usual;
    }
  }
  .button-container {
    margin-top: 72px;
  }
}
@media only screen and (max-width: 600px) {
  .main-card {
    margin-top: 48px;
    h5 {
      margin-top: 56px !important;
    }
    .desc {
      margin-top: 24px !important;
      margin-bottom: 4px !important;
    }
    .button-container {
      margin-top: 48px !important;
    }
  }
}
